/** @jsx jsx */
import {  jsx } from "theme-ui"
import React, { useState, useEffect } from "react";

import DesktopList from './desktoplist';

import { Link, graphql, useStaticQuery } from "gatsby"

export default function DesktopNav( menu ) {
	// determined if page has scrolled and if the view is on mobile
	const [scrolled, setScrolled] = useState(false);
    const responsive = "desktop"

	// change state on scroll
	useEffect(() => {
			const handleScroll = () => {
			const isScrolled = window.scrollY > 150;
			if (isScrolled !== scrolled) {
				setScrolled(!scrolled);
			}
		};

		document.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener('scroll', handleScroll);
		};
	}, [scrolled]);

    const data = useStaticQuery(graphql`
        query {
          allSitePage(filter: { context: { showInNavigation: { eq: true } } }) {
            nodes {
              id
              path
              context {
                heading
                showInNavigation
              }
            }
          }
          file(name: {eq: "cap"}) {
            id
            publicURL
          }      
        }
    `)


	return(
	<>
    	<nav data-active={scrolled} className="hidden lg:block w-full text-gray-700 px-5 xl:px-0 bg-white fixed top-0 animated z-40">
      		<div className="flex flex-col w-full max-w-screen-xl mx-auto lg:items-stretch lg:justify-between lg:flex-row">
        		<div className="px-6 lg:px-0 flex flex-row items-center justify-between">
					<Link to="/" className="tracking-widest rounded-lg focus:outline-none focus:shadow-outline">
						<div className="flex items-center">
							<img width="85" src={data.file.publicURL} className="float-left" alt="HeadsSpace Logo" />
							<h3 sx={{ color: 'greenblue' }} className="ml-3 float-left">Heads' Space</h3>
						</div>
					</Link>
        		</div>	

        		{/* START DesktopNav */}
				<DesktopList/>
				{/* END DesktopNav */}
			</div>
		</nav>
	</>
	)
}