/** @jsx jsx */
import {  jsx } from "theme-ui"

// https://codesandbox.io/s/infinite-multi-level-menu-react-js-89fmj?file=/src/MultiMenus.js

import React, { useState, useEffect } from "react";

import MobileList from './mobilelist';

import { Link, graphql, useStaticQuery } from "gatsby"

export default function MobileNav( ) {

    const data = useStaticQuery(graphql`
        query {
          allSitePage(filter: { context: { showInNavigation: { eq: true } } }) {
            nodes {
              id
              path
              context {
                heading
                showInNavigation
              }
            }
          }
          file(name: {eq: "cap"}) {
            id
            publicURL
          }      
        }
    `)


    const [show, setShow] = useState(null);
    const [scrolled, setScrolled] = useState(false);
    const responsive = "mobile"   


    // change state on scroll
    useEffect(() => {
            const handleScroll = () => {
            const isScrolled = window.scrollY > 80;
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);


    return (
        <>
            <div className={show ? "mobilenav-container lg:hidden shadow-lg lg:w-76 w-64 z-40 fixed z-40 top-0 bg-white shadow min-h-screen flex-col justify-between pb-12 transition duration-150 ease-in-out transform  translate-x-0" : "lg:w-76  lg:hidden w-64 z-40 fixed z-40 top-0 bg-white shadow min-h-screen flex-col justify-between pb-12 transition duration-150 ease-in-out transform  -translate-x-full"}>
                {" "}
                <div className="lg:px-6 px-4 flex justify-between items-center pt-6">
                    <Link to="/" className="cursor-pointer w-full">
                        <img width="136" height="73" src={data.file.publicURL}  className="w-4/6 mx-auto" alt="Heads Space Logo" />
                    </Link>
                    <div role="button" tabIndex="0" className="cursor-pointer lg:block hidden" onClick={() => setShow(!show)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 40 40" fill="none">
                            <rect x="4.09033" y="0.0544434" width={50} height={5} rx="2.5" transform="rotate(45 4.09033 0.0544434)" fill="white" />
                            <rect x="0.554688" y="36.4097" width={50} height={5} rx="2.5" transform="rotate(-45 0.554688 36.4097)" fill="white" />
                        </svg>
                    </div>
                    <div role="button" tabIndex="0" className="cursor-pointer lg:hidden block" onClick={() => setShow(!show)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 40 40" fill="none">
                            <rect x="4.09033" y="0.0544434" width={50} height={5} rx="2.5" transform="rotate(45 4.09033 0.0544434)" fill="white" />
                            <rect x="0.554688" y="36.4097" width={50} height={5} rx="2.5" transform="rotate(-45 0.554688 36.4097)" fill="white" />
                        </svg>
                    </div>
                </div>
                <div className="lg:px-6 px-4">
                    <MobileList />
                </div>
            </div>
            <nav data-active={scrolled} className="fixed w-full py-4 w-full relative lg:hidden bg-white">
                <div className="mx-auto lg:px-0 px-6 h-full w-full relative">

                    <nav className="lg:hidden">
                        <div className="flex items-center">
                            <div role="button" tabIndex="0" className="cursor-pointer pr-6 absolute right-0" onClick={() => setShow(!show)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 50 31" fill="none">
                                    <rect x={50} y={31} width={50} height={5} rx="2.5" transform="rotate(180 50 31)" fill="#1f4871" />
                                    <rect x={50} y={5} width={50} height={5} rx="2.5" transform="rotate(180 50 5)" fill="#1f4871" />
                                    <rect x={20} y={18} width={50} height={5} rx="2.5" transform="rotate(180 35 18)" fill="#1f4871" />
                                </svg>
                            </div>
                            <Link to="/" className="cursor-pointer flex flex-wrap items-center">
                                <div className="float-left">
                                    <img width="160" height="85" src={data.file.publicURL}  className="fade-in w-16 h-16" alt="Heads Space Logo" />
                                </div>
                                <h3 sx={{ color: 'greenblue' }} className="ml-3 float-left">Heads' Space</h3>
                            </Link>
                        </div>
                    </nav>
                </div>            
            </nav>
        </>
    );
}
