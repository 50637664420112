/** @jsx jsx */
import {  jsx } from "theme-ui"
import React from 'react'
import { useState } from "react";

import { DeskItem } from './menuStyle'
import UniversalLink from "./UniversalLink"

import scrollTo from 'gatsby-plugin-smoothscroll';

export default function DesktopList( menu ) {
	const [activeMenus] = useState([]);

    return(
        <>
            <ul className="mt-8 lg:mt-0 lg:flex lg:items-center">
                <li className="lg:inline-block lg:h-full px-4">
                    <DeskItem className="table text-center">
                        <UniversalLink className="pt-2 table-cell align-middle w-full h-full hover:bg-gray-200 pb-2 px-6 cursor-pointer w-full" onClick={() => scrollTo('#about')}>About</UniversalLink>
                    </DeskItem>
                </li>
                <li className="lg:inline-block lg:h-full px-4">
                    <DeskItem className="table text-center">
                        <UniversalLink className="pt-2 table-cell align-middle w-full h-full hover:bg-gray-200 pb-2 px-6 cursor-pointer w-full" onClick={() => scrollTo('#services')}>Services</UniversalLink>
                    </DeskItem>
                </li>
                <li className="lg:inline-block lg:h-full px-4">
                    <DeskItem className="table text-center">
                        <UniversalLink className="pt-2 table-cell align-middle w-full h-full hover:bg-gray-200 pb-2 px-6 cursor-pointer w-full" onClick={() => scrollTo('#references')}>References</UniversalLink>
                    </DeskItem>
                </li>
                <li className="lg:inline-block lg:h-full px-4">
                    <DeskItem className="table text-center">
                        <UniversalLink className="pt-2 table-cell align-middle w-full h-full hover:bg-gray-200 pb-2 px-6 cursor-pointer w-full" onClick={() => scrollTo('#contact')}>Contact</UniversalLink>
                    </DeskItem>
                </li>
            </ul>
        </>
    )
}