/** @jsx jsx */
import {  jsx } from "theme-ui"
import React from 'react'
import { useState } from "react";

import { Item, Arrow } from './menuStyle'
import UniversalLink from "./UniversalLink"
import MobileNav from './mobilenav';

import scrollTo from 'gatsby-plugin-smoothscroll';

export default function MobileList( menu ) {
	const [activeMenus, setActiveMenus] = useState([]);

    // const ListMenu = () => {

    //     return (
    //     <>
    //         <li className="lg:inline-block lg:h-full">
    //             <Item dept={dept}>
    //                 <UniversalLink className="lg:hidden hover:bg-gray-100" to={data.url}>{data.label} </UniversalLink>
    //                 {hasSubMenu && (
    //                     <Arrow
    //                         onClick={() => handleArrowClick(menuName)}
    //                         toggle={activeMenus.includes(menuName)}
    //                     />
    //                 )}
    //             </Item>
    //             {hasSubMenu && (
    //             <SubMenu
    //                 dept={dept}
    //                 data={data.children}
    //                 toggle={activeMenus.includes(menuName)}
    //                 menuIndex={menuIndex}
    //             />
    //             )}
    //         </li>
    //     </>
    //     )
    // };    


	return(
		<>
	        <ul className="mobilelist mt-8 lg:mt-0 lg:flex lg:items-center">
                <li className="lg:inline-block lg:h-full">
                    <Item>
                        <UniversalLink className="lg:hidden hover:bg-gray-100 pt-2 pb-2 px-2 cursor-pointer w-full" onClick={() => scrollTo('#about')}>About</UniversalLink>
                    </Item>
                    <Item>
                        <UniversalLink className="lg:hidden hover:bg-gray-100 pt-2 pb-2 px-2 cursor-pointer w-full" onClick={() => scrollTo('#services')}>Services</UniversalLink>
                    </Item>
                    <Item>
                        <UniversalLink className="lg:hidden hover:bg-gray-100 pt-2 pb-2 px-2 cursor-pointer w-full" onClick={() => scrollTo('#references')}>References</UniversalLink>
                    </Item>
                    <Item>
                        <UniversalLink className="lg:hidden hover:bg-gray-100 pt-2 pb-2 px-2 cursor-pointer w-full" onClick={() => scrollTo('#contact')}>Contact</UniversalLink>
                    </Item>
                </li>
            </ul>
		</>
	)
}